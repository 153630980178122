// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("App/component/img/svg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("App/component/img/svg2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #ccbcbc;
    width: 100%;
    min-height: 100px;
    margin: 0;
    padding: 0;
    font-family: "Luckiest Guy", cursive;
}

#background {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

#background::before, #background::after{
    content: "";
    position: absolute;
    z-index: -1;
} 

#background::before {
    width: 400%;
    height: 400%;
    top: -150%;
    left: -150%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 0 repeat;
    transform: rotate(30deg);
}

#background::after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) 0 0 no-repeat;
}

@media only screen and (max-width: 1367px) {
    #background::before {
        background-size: 30vh; /* SVG для мобильных устройств */
    }
}

@media only screen and (min-width: 1368px) {
    #background::before {
        background-size: 20vw; /* SVG для десктопных устройств */
    }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,WAAW;IACX,8DAAuD;IAKvD,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,iEAA2D;AAC/D;;AAEA;IACI;QACI,qBAAqB,EAAE,gCAAgC;IAC3D;AACJ;;AAEA;IACI;QACI,qBAAqB,EAAE,iCAAiC;IAC5D;AACJ","sourcesContent":["body {\n    background-color: #ccbcbc;\n    width: 100%;\n    min-height: 100px;\n    margin: 0;\n    padding: 0;\n    font-family: \"Luckiest Guy\", cursive;\n}\n\n#background {\n    position: relative;\n    overflow: hidden;\n    width: 100vw;\n    height: 100vh;\n}\n\n#background::before, #background::after{\n    content: \"\";\n    position: absolute;\n    z-index: -1;\n} \n\n#background::before {\n    width: 400%;\n    height: 400%;\n    top: -150%;\n    left: -150%;\n    background: url(\"App/component/img/svg.svg\") 0 0 repeat;\n    -webkit-transform: rotate(30deg);\n    -moz-transform: rotate(30deg);\n    -ms-transform: rotate(30deg);\n    -o-transform: rotate(30deg);\n    transform: rotate(30deg);\n}\n\n#background::after {\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: url(\"App/component/img/svg2.svg\") 0 0 no-repeat;\n}\n\n@media only screen and (max-width: 1367px) {\n    #background::before {\n        background-size: 30vh; /* SVG для мобильных устройств */\n    }\n}\n\n@media only screen and (min-width: 1368px) {\n    #background::before {\n        background-size: 20vw; /* SVG для десктопных устройств */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
