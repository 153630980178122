import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./loginReg.css";
import logo from "../img/logo.png";
import * as valid from "email-validator";
import UserReg from '../../../API/RegUser';
import { moveToLocalStore } from '../../../features/store';

const RegPage = () => {
    useEffect(() => {
        ClearingLocalHost()
    }, [])

    function ClearingLocalHost(){
        const keys = ["gameName","opponentId", "player1Skin", "player2Skin",
        "winFlag", "moves", "player1Login","player2Login","isPrivate", "isBot",
        "difficulty"]
        for (var k = 0; k < keys.length; k += 1){
            localStorage.removeItem(keys[k])
        }
    } 

    const [data, setData] = useState({});
    const navigate = useNavigate();
    async function reg(){
        if (valid.validate(data.email)){
            if (data.password.length >= 5 && data.password.length <= 20){
                if (data.login.length >= 5 && data.login.length <= 20){
                    const res = await UserReg.Reg(data)
                    if (!res.result){
                        alert("Error:" + res.message);
                    }
                    else{
                        moveToLocalStore({...res, isLogin: true});
                        navigate('/', {replace: false})
                    }
                }
                else alert("Invalid login length");
            }
            else alert("Invalid password length");
        }
        else alert("Invalid e-mail");
    }

    return(
        <div className='container'>
            <img src= {logo} alt = "logo"></img>
            <h1>Sing up</h1>
            <div className='group'>
                <div className='gp' id='gp-in'>  
                    <label>e-mail</label>
                    <input 
                    type='email'
                    className='inp-group'
                    onChange={e => setData({...data,email: e.target.value})} />
                    <label>Login</label>
                    <input
                    type='login'
                    className='inp-group' 
                    onChange={e => setData({...data,login: e.target.value})} />
                    <label>Password</label>
                    <input 
                    type='password'
                    className='inp-group' 
                    onChange={e => setData({...data,password: e.target.value})} />
                </div>
                <div className='gp' id='gp-btn'>
                    <button className='confirm'onClick={reg} id='reg'> Confirm </button>
                    <button onClick={()=>{navigate('/login')}}>Log in</button>
                </div>
            </div>
        </div>
    );
}

export default RegPage;