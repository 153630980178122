import React from "react";
import classes from "./loading.module.css";

const Loading = () =>{
    return(
        <div className={classes.loader}>

        </div>
    )
}

export default Loading;