// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_settingsContainer__xGHIB{
    width: 600px;
    height: 550px;
    background-color: white;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;

    border-radius: 30px;
    text-align: center;
}
h1{
    margin-top: 15px;

}
.settings_inpName__RGAng{
    width: 200px;
    height: 30px;

    display: block;
    margin: auto;
    margin-bottom: 10px;
}
.settings_label__H4xcc{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
::placeholder{
    text-align: center;
}
.settings_sk__FOOi2{
    margin-top: 20px;
}
.settings_btnBack__CoAJs{
    margin-top: 20px;
}

  `, "",{"version":3,"sources":["webpack://./src/App/component/settings/settings.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,gBAAgB;;AAEpB;AACA;IACI,YAAY;IACZ,YAAY;;IAEZ,cAAc;IACd,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".settingsContainer{\n    width: 600px;\n    height: 550px;\n    background-color: white;\n    \n    position: absolute;\n    top:0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n\n    border-radius: 30px;\n    text-align: center;\n}\nh1{\n    margin-top: 15px;\n\n}\n.inpName{\n    width: 200px;\n    height: 30px;\n\n    display: block;\n    margin: auto;\n    margin-bottom: 10px;\n}\n.label{\n    display: flex;\n    justify-content: center;\n    margin-bottom: 10px;\n}\n::placeholder{\n    text-align: center;\n}\n.sk{\n    margin-top: 20px;\n}\n.btnBack{\n    margin-top: 20px;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsContainer": `settings_settingsContainer__xGHIB`,
	"inpName": `settings_inpName__RGAng`,
	"label": `settings_label__H4xcc`,
	"sk": `settings_sk__FOOi2`,
	"btnBack": `settings_btnBack__CoAJs`
};
export default ___CSS_LOADER_EXPORT___;
