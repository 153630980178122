// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 300px;
    height: 450px;
    background-color: white;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;

    border-radius: 30px;
}
h1{
    text-align: center;
}
.group{
    width: 200px;
    height: 250px;
    
    margin: auto;
}
.inp-group{
    width: 200px;
    height: 25px;

    display: block;
    margin: auto;
    margin-bottom: 10px;
}

input{
    font-family: "Luckiest Guy", cursive;
    font-size: medium;
    border: 0;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
}

img{
    height: 70px;

    display: block;
    margin: auto;
    margin-top: 25px;
}
h1{
    margin: 15px;
}
button{
    display: block;
    margin: auto;

    border-radius: 10px;
    border: 0;
    margin-top: 10px;
    width: 75px;
    height: 25px;
    font-family: "Luckiest Guy", cursive;
    font-size: medium;
}
.confirm{
    width: 100px;
    height: 35px;
}

`, "",{"version":3,"sources":["webpack://./src/App/component/LoginRegPages/loginReg.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;;IAEb,YAAY;AAChB;AACA;IACI,YAAY;IACZ,YAAY;;IAEZ,cAAc;IACd,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,SAAS;IACT,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;;IAEZ,cAAc;IACd,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;IACd,YAAY;;IAEZ,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".container {\n    width: 300px;\n    height: 450px;\n    background-color: white;\n    \n    position: absolute;\n    top:0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n\n    border-radius: 30px;\n}\nh1{\n    text-align: center;\n}\n.group{\n    width: 200px;\n    height: 250px;\n    \n    margin: auto;\n}\n.inp-group{\n    width: 200px;\n    height: 25px;\n\n    display: block;\n    margin: auto;\n    margin-bottom: 10px;\n}\n\ninput{\n    font-family: \"Luckiest Guy\", cursive;\n    font-size: medium;\n    border: 0;\n    background-color: #f0f0f0;\n    border-radius: 5px;\n    padding: 5px;\n}\n\nimg{\n    height: 70px;\n\n    display: block;\n    margin: auto;\n    margin-top: 25px;\n}\nh1{\n    margin: 15px;\n}\nbutton{\n    display: block;\n    margin: auto;\n\n    border-radius: 10px;\n    border: 0;\n    margin-top: 10px;\n    width: 75px;\n    height: 25px;\n    font-family: \"Luckiest Guy\", cursive;\n    font-size: medium;\n}\n.confirm{\n    width: 100px;\n    height: 35px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
