// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gameLogin_main__udxAS{
    width: 400px;
    height: 400px;
    background-color: white;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;

    border-radius: 30px;
    padding: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.gameLogin_inside__9ITGa{
    width: 200px;

    margin: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
p{
    margin: 0;
}
.gameLogin_inp__AwJXS{
    width: 200px;
    height: 25px;
}
.gameLogin_btn__kyDw2{
    margin-top: 25px;
}
.gameLogin_backbtn__O6LjS{
    width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/App/component/findGame/gameLogin/gameLogin.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ,mBAAmB;IACnB,aAAa;;IAEb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,YAAY;;IAEZ,YAAY;;IAEZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,SAAS;AACb;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;AACf","sourcesContent":[".main{\n    width: 400px;\n    height: 400px;\n    background-color: white;\n    \n    position: absolute;\n    top:0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n\n    border-radius: 30px;\n    padding: 25px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.inside{\n    width: 200px;\n\n    margin: 25px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\np{\n    margin: 0;\n}\n.inp{\n    width: 200px;\n    height: 25px;\n}\n.btn{\n    margin-top: 25px;\n}\n.backbtn{\n    width: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `gameLogin_main__udxAS`,
	"inside": `gameLogin_inside__9ITGa`,
	"inp": `gameLogin_inp__AwJXS`,
	"btn": `gameLogin_btn__kyDw2`,
	"backbtn": `gameLogin_backbtn__O6LjS`
};
export default ___CSS_LOADER_EXPORT___;
