// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile-grid {
  width: 400px;
  margin-left: 100px;
  margin-right: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}
.tile {
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgb(225, 225, 225);
  border: 2px solid black;
  border-radius: 10px;
}
  
.tile:hover {
  opacity: 0.7;
}
  
.selected {
  border: 2px solid rgb(60, 214, 84);
  background-color: white;
  border-radius: 10px;
}
.image{
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/App/component/settings/settings.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,4DAA4D;EAC5D,aAAa;AACf;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,oCAAoC;EACpC,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd","sourcesContent":[".tile-grid {\n  width: 400px;\n  margin-left: 100px;\n  margin-right: 100px;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));\n  grid-gap: 5px;\n}\n.tile {\n  cursor: pointer;\n  transition: all 0.3s ease;\n  background-color: rgb(225, 225, 225);\n  border: 2px solid black;\n  border-radius: 10px;\n}\n  \n.tile:hover {\n  opacity: 0.7;\n}\n  \n.selected {\n  border: 2px solid rgb(60, 214, 84);\n  background-color: white;\n  border-radius: 10px;\n}\n.image{\n  height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
