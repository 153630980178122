// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rulesPage_container__Ap2-K{
    background-color: rgba(237,226,226,0.8);
    background-size: 100vw 100vh;

    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}
.rulesPage_flex__ji2iw{
    display: flex;
    align-items: center;
}
h1{
    text-align: center;
    margin-top: 50px;
}

.rulesPage_rules__J9CNd{
    background-color:rgba(0,0,0,0);;
    width: 45vw;
    height: 45vh;

    text-align: center;
    
}
.rulesPage_photo__jO98z{
    height: 200px;

    display: block;
    padding-top: 8px;
    padding-left: 5px;
}
.rulesPage_backdrop__dDVEq{
    background-color: white;
    width: 210px;
    height: 210px;

    border-radius: 20px;
    margin: auto;
}
.rulesPage_btn__fPbvn{
    width: 80px;
    height: 40px;

    position: relative;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}
.rulesPage_txt__5bHKX{
    width: 210px;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/App/component/rules/rulesPage.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,4BAA4B;;IAE5B,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,YAAY;;IAEZ,kBAAkB;;AAEtB;AACA;IACI,aAAa;;IAEb,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;;IAEb,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;;IAEZ,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;AAChB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".container{\n    background-color: rgba(237,226,226,0.8);\n    background-size: 100vw 100vh;\n\n    position: absolute;\n    top:0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n}\n.flex{\n    display: flex;\n    align-items: center;\n}\nh1{\n    text-align: center;\n    margin-top: 50px;\n}\n\n.rules{\n    background-color:rgba(0,0,0,0);;\n    width: 45vw;\n    height: 45vh;\n\n    text-align: center;\n    \n}\n.photo{\n    height: 200px;\n\n    display: block;\n    padding-top: 8px;\n    padding-left: 5px;\n}\n.backdrop{\n    background-color: white;\n    width: 210px;\n    height: 210px;\n\n    border-radius: 20px;\n    margin: auto;\n}\n.btn{\n    width: 80px;\n    height: 40px;\n\n    position: relative;\n    top: 150px;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n}\n.txt{\n    width: 210px;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rulesPage_container__Ap2-K`,
	"flex": `rulesPage_flex__ji2iw`,
	"rules": `rulesPage_rules__J9CNd`,
	"photo": `rulesPage_photo__jO98z`,
	"backdrop": `rulesPage_backdrop__dDVEq`,
	"btn": `rulesPage_btn__fPbvn`,
	"txt": `rulesPage_txt__5bHKX`
};
export default ___CSS_LOADER_EXPORT___;
