// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_loader__ulDgF{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px dashed black;

    animation: loading_rotate__I6Cto 1s infinite linear;
}

@keyframes loading_rotate__I6Cto {
    from{
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/App/component/findGame/loading/loading.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,wBAAwB;;IAExB,mDAAoC;AACxC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader{\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    border: 3px dashed black;\n\n    animation: rotate 1s infinite linear;\n}\n\n@keyframes rotate {\n    from{\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loading_loader__ulDgF`,
	"rotate": `loading_rotate__I6Cto`
};
export default ___CSS_LOADER_EXPORT___;
