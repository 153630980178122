// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findGame_fgpContainer__I4y9G{
    width: 450px;
    height: 450px;
    background-color: white;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;

    border-radius: 30px;
}
.findGame_fgpPosts__la3zQ{
    background-color: rgb(227, 219, 219, 0.2);
    width: 350px;
    height: 300px;
    margin-left: 50px;
    margin-bottom: 30px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.findGame_fgpInp__GKHNc{
    width: 300px;
    height: 30px;
    background-color: rgb(237, 226, 226, 0.54);
    border: 0px solid white;
    margin-top: 10px;
    border-radius: 10px;
}
.findGame_fgpMenu__xyokQ{
    width: 300px;
    height: 250px;
    overflow: auto;
}
.findGame_post__ue59A{
    background-color: rgb(237, 226, 226, 0.54);
    width: 250px;
    height: 40px;
    border-radius: 10px;

    display: flex;
    padding-left: 20px;
    margin-top: 5px;
}
.findGame_postName__uAcvd{
    margin-top: 12px;
}
.findGame_imagePrivateGame__2NjUz{
    width: 20px;
    height: 25px;

    margin-top: 7px;
    margin-bottom: 0;
    margin-right: 5px;
    margin-left: 0;
    display: flex;

}`, "",{"version":3,"sources":["webpack://./src/App/component/findGame/findGame.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;;IAEvB,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ,mBAAmB;AACvB;AACA;IACI,yCAAyC;IACzC,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;;IAEnB,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,0CAA0C;IAC1C,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;AACA;IACI,0CAA0C;IAC1C,YAAY;IACZ,YAAY;IACZ,mBAAmB;;IAEnB,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;;IAEZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,aAAa;;AAEjB","sourcesContent":[".fgpContainer{\n    width: 450px;\n    height: 450px;\n    background-color: white;\n    \n    position: absolute;\n    top:0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    \n    margin: auto;\n\n    border-radius: 30px;\n}\n.fgpPosts{\n    background-color: rgb(227, 219, 219, 0.2);\n    width: 350px;\n    height: 300px;\n    margin-left: 50px;\n    margin-bottom: 30px;\n    border-radius: 10px;\n\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n.fgpInp{\n    width: 300px;\n    height: 30px;\n    background-color: rgb(237, 226, 226, 0.54);\n    border: 0px solid white;\n    margin-top: 10px;\n    border-radius: 10px;\n}\n.fgpMenu{\n    width: 300px;\n    height: 250px;\n    overflow: auto;\n}\n.post{\n    background-color: rgb(237, 226, 226, 0.54);\n    width: 250px;\n    height: 40px;\n    border-radius: 10px;\n\n    display: flex;\n    padding-left: 20px;\n    margin-top: 5px;\n}\n.postName{\n    margin-top: 12px;\n}\n.imagePrivateGame{\n    width: 20px;\n    height: 25px;\n\n    margin-top: 7px;\n    margin-bottom: 0;\n    margin-right: 5px;\n    margin-left: 0;\n    display: flex;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fgpContainer": `findGame_fgpContainer__I4y9G`,
	"fgpPosts": `findGame_fgpPosts__la3zQ`,
	"fgpInp": `findGame_fgpInp__GKHNc`,
	"fgpMenu": `findGame_fgpMenu__xyokQ`,
	"post": `findGame_post__ue59A`,
	"postName": `findGame_postName__uAcvd`,
	"imagePrivateGame": `findGame_imagePrivateGame__2NjUz`
};
export default ___CSS_LOADER_EXPORT___;
